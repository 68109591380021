/// REACT ///
import { useState } from "react";
/// REDUX ///
import { useAppSelector } from "api/hooks/apiHook";
/// MUI ///
import { Button } from "@mui/material";
import { Checkbox } from "@mui/material";
import { Dialog } from "@mui/material";
import { DialogActions } from "@mui/material";
import { DialogContent } from "@mui/material";
import { DialogTitle } from "@mui/material";
import { FormControl } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { FormGroup } from "@mui/material";
import { MenuItem } from "@mui/material";
import { TextField } from "@mui/material";
import { Typography } from "@mui/material";
/// TYPES ///
import { Price } from "api/types/price";
import { PriceError } from "api/types/price";
import { PriceHasError } from "api/types/price";
/// VALUES ///
import { empty_error } from "values/empty/price";
import { empty_has_error } from "values/empty/price";
/// STYLES ///
import { dialogContentStyle } from "styles/style";
import { textFieldStyle } from "styles/style";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";
import { CM2 } from "values/material";
import { CM3 } from "values/material";
import { CM12 } from "values/material";
import { CM1 } from "values/material";
import { CM60 } from "values/material";
import { CM08 } from "values/material";
/// COLOR ///
import { primary_color } from "styles/style";
import { white_color } from "styles/style";

interface Props {
  title: string,
  open: boolean,
  setOpen: (open: boolean) => void,
  create: (price: Price) => void
}

export default function CreatePrice({
  title,
  open,
  setOpen,
  create
}: Props) {
  const [price, setPrice] = useState<Price>({});
  const [errors, setErrors] = useState<PriceError>({});
  const [has_errors, setHasErrors] = useState<PriceHasError>({});
  const { price_list } = useAppSelector(state => state.price);

  const handleCreate = () => {
    let error = false;
    let errors: PriceError = empty_error;
    let has_errors: PriceHasError = empty_has_error;

    if (has_errors.price || price.price === 0) {
      errors = {
        ...errors,
        price: "Please enter a number for the price"
      }
      has_errors = {
        ...has_errors,
        price: true
      }
      error = true;
    }

    if (!price_list.id) {
      errors = {
        ...errors,
        price_list: "An unknown error has occurred. Please contact the administrator"
      }
      error = true;
    }

    if (error) {
      setErrors(errors);
      setHasErrors(has_errors);
      return;
    }

    create({ ...price, price_list: price_list.id });
    handleClose();
  }

  const handleClose = () => {
    setPrice({});
    setOpen(false);
    setErrors({});
    setHasErrors({})
  }

  return (
    <Dialog fullWidth={true} maxWidth={"md"} open={open}>
      <DialogTitle sx={{ backgroundColor: primary_color, color: white_color }}>{title}</DialogTitle>
      <DialogContent sx={dialogContentStyle}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          select
          defaultValue={CM2}
          helperText={errors?.price_list ?? ""}
          id="height"
          label="Material Height"
          onChange={e => setPrice({ ...price, material_thickness: e.target.value })}>
          <MenuItem key={CM2} value={CM2}>
            {getMaterialHeightDisplay(CM2)}
          </MenuItem>
          <MenuItem key={CM3} value={CM3}>
            {getMaterialHeightDisplay(CM3)}
          </MenuItem>
          <MenuItem key={CM12} value={CM12}>
            {getMaterialHeightDisplay(CM12)}
          </MenuItem>
          <MenuItem key={CM1} value={CM1}>
            {getMaterialHeightDisplay(CM1)}
          </MenuItem>
          <MenuItem key={CM60} value={CM60}>
            {getMaterialHeightDisplay(CM60)}
          </MenuItem>
          <MenuItem key={CM08} value={CM08}>
            {getMaterialHeightDisplay(CM08)}
          </MenuItem>
        </TextField>
        <TextField
          sx={textFieldStyle}
          fullWidth
          size="small"
          variant="outlined"
          id="price"
          label="Price"
          type="number"
          helperText={errors?.price ?? ""}
          defaultValue={0}
          error={has_errors.price}
          onChange={(e) => setPrice({ ...price, price: Number(e.target.value) })} />
        <FormControl fullWidth sx={{ paddingTop: 1, paddingLeft: 1 }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  onChange={(e) => setPrice({ ...price, taxable: e.target.checked })}
                  defaultChecked />
              }
              label={<Typography variant="body2">Taxable</Typography>} />
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleCreate}>Create</Button>
      </DialogActions>
    </Dialog>
  )
}

