import { SinkCutout } from "api/types/price";

export enum ActionType {
  GET_SINK_CUTOUTS_PENDING = "GET_SINK_CUTOUTS_PENDING",
  GET_SINK_CUTOUTS_SUCCESS = "GET_SINK_CUTOUTS_SUCCESS",
  GET_SINK_CUTOUTS_FAIL = "GET_SINK_CUTOUTS_FAIL",
  CREATE_SINK_CUTOUT_SUCCESS = "CREATE_SINK_CUTOUT_SUCCESS",
  CREATE_SINK_CUTOUT_FAIL = "CREATE_SINK_CUTOUT_FAIL",
  CREATE_SINK_CUTOUT_PENDING = "CREATE_SINK_CUTOUT_PENDING",
  UPDATE_SINK_CUTOUT_PENDING = "UPDATE_SINK_CUTOUT_PENDING",
  UPDATE_SINK_CUTOUT_SUCCESS = "UPDATE_SINK_CUTOUT_SUCCESS",
  UPDATE_SINK_CUTOUT_FAIL = "UPDATE_SINK_CUTOUT_FAIL",
  UPDATE_LOCAL_SINK_CUTOUT = "UPDATE_LOCAL_SINK_CUTOUT"
}

interface getSinkCutoutsFail {
  type: ActionType.GET_SINK_CUTOUTS_FAIL,
  payload: string
}

interface getSinkCutoutsSuccess {
  type: ActionType.GET_SINK_CUTOUTS_SUCCESS,
  payload: SinkCutout[]
}

interface getSinkCutoutsPending {
  type: ActionType.GET_SINK_CUTOUTS_PENDING
}

interface createSinkCutoutSuccess {
  type: ActionType.CREATE_SINK_CUTOUT_SUCCESS,
  payload: SinkCutout
}

interface createSinkCutoutFail {
  type: ActionType.CREATE_SINK_CUTOUT_FAIL,
  payload: string
}

interface createSinkCutoutPending {
  type: ActionType.CREATE_SINK_CUTOUT_PENDING
}

interface updateSinkCutoutPending {
  type: ActionType.UPDATE_SINK_CUTOUT_PENDING
}

interface updateSinkCutoutSuccess {
  type: ActionType.UPDATE_SINK_CUTOUT_SUCCESS,
  payload: SinkCutout
}

interface updateSinkCutoutFail {
  type: ActionType.UPDATE_SINK_CUTOUT_FAIL,
  payload: string
}

interface IUpdateLocalSinkCutout {
  type: ActionType.UPDATE_LOCAL_SINK_CUTOUT,
  payload: SinkCutout
}

export type Action =
  getSinkCutoutsFail |
  getSinkCutoutsPending |
  getSinkCutoutsSuccess |
  createSinkCutoutPending |
  createSinkCutoutFail |
  createSinkCutoutSuccess |
  updateSinkCutoutFail |
  updateSinkCutoutPending |
  updateSinkCutoutSuccess |
  IUpdateLocalSinkCutout;
