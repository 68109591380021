/// REACT ///
import { useCallback } from "react";
/// MUI ///
import { Checkbox } from "@mui/material";
import { InputAdornment } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
/// STYLE ///
import { gridSpacing } from "styles/style";
import { getRowSecondStyle } from "values/style";
/// COLOR ///
import { grey_color } from "styles/style";
/// LODASH ///
import { debounce } from "lodash";

interface Props {
  index: number,
  name: string,
  price: number,
  measurement: string,
  taxable: boolean,
  setPrice: (price: number) => void,
  setTaxable: (taxable: boolean) => void
}

export default function EditablePrice({
  index,
  name,
  price,
  measurement,
  taxable,
  setPrice,
  setTaxable
}: Props) {

  const updatePrice = useCallback(debounce(function (price: number) {
    setPrice(price);
  }, 1000), []);

  const updateTaxable = useCallback(debounce(function (taxable: boolean) {
    setTaxable(taxable);
  }, 1000), []);

  return (
    <Grid container item sx={[{ padding: 1 }, getRowSecondStyle(index)]} alignItems="center">
      <Grid item xs={4} sx={gridSpacing}>
        <Typography variant="body2">
          {name}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={gridSpacing}>
        <TextField
          onChange={e => updatePrice(Number(e.target.value))}
          size="small"
          InputLabelProps={{ shrink: true }}
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Typography variant="body2" sx={{
                  paddingBottom: "13px",
                  color: grey_color
                }}>
                  {measurement}
                </Typography>
              </InputAdornment>
            ),
          }}
          type="number"
          defaultValue={price}>
        </TextField>
      </Grid >
      <Grid item xs={2} sx={gridSpacing}>
        <Checkbox
          sx={{ marginLeft: 0.5 }}
          size="small"
          onChange={(e) => updateTaxable(e.target.checked)}
          defaultChecked={taxable} />
      </Grid>
    </Grid>
  )
}
