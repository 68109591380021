import { Miter } from "api/types/price";

export enum ActionType {
  GET_MITERS_PENDING = "GET_MITERS_PENDING",
  GET_MITERS_SUCCESS = "GET_MITERS_SUCCESS",
  GET_MITERS_FAIL = "GET_MITERS_FAIL",
  CREATE_MITER_PENDING = "CREATE_MITER_PENDING",
  CREATE_MITER_SUCCESS = "CREATE_MITER_SUCCESS",
  CREATE_MITER_FAIL = "CREATE_MITER_FAIL",
  UPDATE_MITER_PENDING = "UPDATE_MITER_PENDING",
  UPDATE_MITER_SUCCESS = "UPDATE_MITER_SUCCESS",
  UPDATE_MITER_FAIL = "UPDATE_MITER_FAIL",
  UPDATE_LOCAL_MITER = "UPDATE_LOCAL_MITER",
}

interface getMitersFail {
  type: ActionType.GET_MITERS_FAIL,
  payload: string
}

interface getMitersSuccess {
  type: ActionType.GET_MITERS_SUCCESS,
  payload: Miter[]
}

interface getMitersPending {
  type: ActionType.GET_MITERS_PENDING
}

interface createMiterFail {
  type: ActionType.CREATE_MITER_FAIL,
  payload: string
}

interface createMiterSuccess {
  type: ActionType.CREATE_MITER_SUCCESS,
  payload: Miter[]
}

interface createMiterPending {
  type: ActionType.CREATE_MITER_PENDING
}

interface updateMiterFail {
  type: ActionType.UPDATE_MITER_FAIL,
  payload: string
}

interface updateMiterSuccess {
  type: ActionType.UPDATE_MITER_SUCCESS,
  payload: Miter
}

interface updateMiterPending {
  type: ActionType.UPDATE_MITER_PENDING
}

interface updateLocalMiter {
  type: ActionType.UPDATE_LOCAL_MITER,
  payload: Miter
}

export type Action =
  getMitersFail |
  getMitersPending |
  getMitersSuccess |
  createMiterPending |
  createMiterSuccess |
  createMiterFail |
  updateMiterPending |
  updateMiterSuccess |
  updateMiterFail |
  updateLocalMiter;
