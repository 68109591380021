import { CooktopCutout } from "api/types/price";

export enum ActionType {
  GET_COOKTOP_CUTOUTS_PENDING = "GET_COOKTOP_CUTOUTS_PENDING",
  GET_COOKTOP_CUTOUTS_SUCCESS = "GET_COOKTOP_CUTOUTS_SUCCESS",
  GET_COOKTOP_CUTOUTS_FAIL = "GET_COOKTOP_CUTOUTS_FAIL",
  CREATE_COOKTOP_CUTOUT_SUCCESS = "CREATE_COOKTOP_CUTOUT_SUCCESS",
  CREATE_COOKTOP_CUTOUT_PENDING = "CREATE_COOKTOP_CUTOUT_PENDING",
  CREATE_COOKTOP_CUTOUT_FAIL = "CREATE_COOKTOP_CUTOUT_FAIL",
  UPDATE_COOKTOP_CUTOUT_PENDING = "UPDATE_COOKTOP_CUTOUT_PENDING",
  UPDATE_COOKTOP_CUTOUT_SUCCESS = "UPDATE_COOKTOP_CUTOUT_SUCCESS",
  UPDATE_COOKTOP_CUTOUT_FAIL = "UPDATE_COOKTOP_CUTOUT_FAIL",
  UPDATE_LOCAL_COOKTOP_CUTOUT = "UPDATE_LOCAL_COOKTOP_CUTOUT"
}

interface getCooktopCutoutsFail {
  type: ActionType.GET_COOKTOP_CUTOUTS_FAIL,
  payload: string
}

interface getCooktopCutoutsSuccess {
  type: ActionType.GET_COOKTOP_CUTOUTS_SUCCESS,
  payload: CooktopCutout[]
}

interface getCooktopCutoutsPending {
  type: ActionType.GET_COOKTOP_CUTOUTS_PENDING
}

interface createCooktopCutoutSuccess {
  type: ActionType.CREATE_COOKTOP_CUTOUT_SUCCESS,
  payload: CooktopCutout
}

interface createCooktopCutoutPending {
  type: ActionType.CREATE_COOKTOP_CUTOUT_PENDING
}

interface createCooktopCutoutFail {
  type: ActionType.CREATE_COOKTOP_CUTOUT_FAIL,
  payload: string
}

interface updateCooktopCutoutPending {
  type: ActionType.UPDATE_COOKTOP_CUTOUT_PENDING
}

interface updateCooktopCutoutSuccess {
  type: ActionType.UPDATE_COOKTOP_CUTOUT_SUCCESS,
  payload: CooktopCutout
}

interface updateCooktopCutoutFail {
  type: ActionType.UPDATE_COOKTOP_CUTOUT_FAIL,
  payload: string
}

interface IUpdateLocalCooktopCutout {
  type: ActionType.UPDATE_LOCAL_COOKTOP_CUTOUT,
  payload: CooktopCutout
}

export type Action =
  getCooktopCutoutsFail |
  getCooktopCutoutsPending |
  getCooktopCutoutsSuccess |
  createCooktopCutoutFail |
  createCooktopCutoutPending |
  createCooktopCutoutSuccess |
  updateCooktopCutoutFail |
  updateCooktopCutoutPending |
  updateCooktopCutoutSuccess |
  IUpdateLocalCooktopCutout;
