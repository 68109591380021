/// ROUTER ///
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/// MUI ///
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
/// STYLES ///
import { drawerListItemStyle } from "styles/drawer";
import { drawerListButtonStyle } from "styles/drawer";
/// URLS ///
import { price_list_url } from "components/navigation/endpoints";
import { edge_url } from "components/navigation/endpoints";

export default function PriceListDrawer() {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <List sx={{ padding: 0 }}>
      <ListItem sx={drawerListItemStyle} key="Details">
        <ListItemButton sx={drawerListButtonStyle} onClick={() => navigate(`/${price_list_url}/${id}`)}>
          <ListItemText>Details</ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem sx={drawerListItemStyle} key="Edges">
        <ListItemButton sx={drawerListButtonStyle} onClick={() => navigate(`/${price_list_url}/${id}/${edge_url}`)}>
          <ListItemText>Edges</ListItemText>
        </ListItemButton>
      </ListItem>
    </List>
  );
}
