/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useParams } from "react-router-dom";
/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// ACTIONS ///
import { getPriceList } from "api/actions/price";
/// MUI ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
/// HOC ///
import PriceListDrawer from "../drawer";
/// TYPES ///
import { PriceList } from "api/types/price";
/// VALUES ///
import { getMaterialTypeDisplay } from "values/material";
/// STYLES ///
import { islandStyle } from "styles/style";
import { mainContentAreaStyle } from "styles/style";

export default function PList() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { price_list: pl } = useAppSelector(state => state.price);
  const [price_list, setPriceList] = useState<PriceList>({});

  useEffect(() => {
    setPriceList(pl);
  }, [pl]);

  useEffect(() => {
    if (id) {
      dispatch(getPriceList(id));
    }
  }, [dispatch, id]);


  return (
    <Box sx={mainContentAreaStyle}>
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <Typography variant="h1">
            {`${price_list?.name ?? ""} Details`}
          </Typography>
        </Grid>
        <Grid item xs={8.5}>
          <Stack sx={islandStyle}>
            <Typography variant="body1">
              <b>Region:</b> {price_list?.region_name ?? ""}
            </Typography>
            <Typography variant="body1">
              <b>Material Type:</b> {getMaterialTypeDisplay(price_list.material_type)}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={2.5}>
          <PriceListDrawer />
        </Grid>
      </Grid>
    </Box>
  )
}
