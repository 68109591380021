import { InsideRadiusCorner } from "api/types/price";

export enum ActionType {
  GET_INSIDE_RADIUS_CORNERS_PENDING = "GET_INSIDE_RADIUS_CORNERS_PENDING",
  GET_INSIDE_RADIUS_CORNERS_SUCCESS = "GET_INSIDE_RADIUS_CORNERS_SUCCESS",
  GET_INSIDE_RADIUS_CORNERS_FAIL = "GET_INSIDE_RADIUS_CORNERS_FAIL",
  CREATE_INSIDE_RADIUS_CORNER_PENDING = "CREATE_INSIDE_RADIUS_CORNER_PENDING",
  CREATE_INSIDE_RADIUS_CORNER_SUCCESS = "CREATE_INSIDE_RADIUS_CORNER_SUCCESS",
  CREATE_INSIDE_RADIUS_CORNER_FAIL = "CREATE_INSIDE_RADIUS_CORNER_FAIL",
  UPDATE_INSIDE_RADIUS_CORNER_PENDING = "UPDATE_INSIDE_RADIUS_CORNER_PENDING",
  UPDATE_INSIDE_RADIUS_CORNER_SUCCESS = "UPDATE_INSIDE_RADIUS_CORNER_SUCCESS",
  UPDATE_INSIDE_RADIUS_CORNER_FAIL = "UPDATE_INSIDE_RADIUS_CORNER_FAIL",
  UPDATE_LOCAL_INSIDE_RADIUS_CORNER = "UPDATE_LOCAL_INSIDE_RADIUS_CORNER",
}

interface getInsideRadiusCornersFail {
  type: ActionType.GET_INSIDE_RADIUS_CORNERS_FAIL,
  payload: string
}

interface getInsideRadiusCornersSuccess {
  type: ActionType.GET_INSIDE_RADIUS_CORNERS_SUCCESS,
  payload: InsideRadiusCorner[]
}

interface getInsideRadiusCornersPending {
  type: ActionType.GET_INSIDE_RADIUS_CORNERS_PENDING
}

interface createInsideRadiusCornerPending {
  type: ActionType.CREATE_INSIDE_RADIUS_CORNER_PENDING,
}

interface createInsideRadiusCornerSuccess {
  type: ActionType.CREATE_INSIDE_RADIUS_CORNER_SUCCESS,
  payload: InsideRadiusCorner
}

interface createInsideRadiusCornerFail {
  type: ActionType.CREATE_INSIDE_RADIUS_CORNER_FAIL,
  payload: string
}

interface updateInsideRadiusCornerPending {
  type: ActionType.UPDATE_INSIDE_RADIUS_CORNER_PENDING,
}

interface updateInsideRadiusCornerSuccess {
  type: ActionType.UPDATE_INSIDE_RADIUS_CORNER_SUCCESS,
  payload: InsideRadiusCorner
}

interface updateInsideRadiusCornerFail {
  type: ActionType.UPDATE_INSIDE_RADIUS_CORNER_FAIL,
  payload: string
}

interface updateLocalInsideRadiusCorner {
  type: ActionType.UPDATE_LOCAL_INSIDE_RADIUS_CORNER,
  payload: InsideRadiusCorner
}

export type Action =
  getInsideRadiusCornersFail |
  getInsideRadiusCornersPending |
  getInsideRadiusCornersSuccess |
  createInsideRadiusCornerFail |
  createInsideRadiusCornerPending |
  createInsideRadiusCornerSuccess |
  updateInsideRadiusCornerFail |
  updateInsideRadiusCornerPending |
  updateInsideRadiusCornerSuccess |
  updateLocalInsideRadiusCorner;
