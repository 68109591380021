/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// ACTIONS ///
import { getPriceLists } from "api/actions/price";
import { getRegions } from "api/actions/region";
/// HOC ///
import Title from "components/universal/title";
import List from "components/universal/list";
import PriceListsItem from "./row";
import Header from "components/universal/header";
import FilterPriceLists from "./filter";
import CreatePriceList from "components/price/dialog/create/price_list";
/// MUI ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
/// STYLES ///
import { islandStyle } from "styles/style";
import { mainContentAreaStyle } from "styles/style";
import { default_pagination_length } from "styles/table";

export default function PriceLists() {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(1);
  const { price_lists } = useAppSelector((state) => state.price);

  useEffect(() => {
    dispatch(getPriceLists());
    dispatch(getRegions(false));
  }, [dispatch]);

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Box sx={mainContentAreaStyle}>
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <Title
            title="Price Lists"
            subtitle={`${price_lists.length} Results`}
            update_pending={false}
            start_times={[]}
            update={() => void 0}
            endProps={<CreatePriceList />} />
        </Grid>
        <Grid item xs={8.5}>
          <Paper sx={[islandStyle]}>
            <Grid container direction="row" justifyContent="space-between">
              <Header
                items={[
                  { text: "Name", width: 2 },
                  { text: "Region", width: 2 },
                  { text: "Status", width: 6, align_right: true },
                  { text: "Units", width: 2, align_right: true }
                ]} />
              <List
                items={price_lists.slice((page - 1) * default_pagination_length, page * default_pagination_length)}
                Item={PriceListsItem} />
              <Grid item xs={12} sx={{ marginTop: 3 }}>
                <Box display="flex" justifyContent="center">
                  <Pagination
                    onChange={changePage}
                    count={
                      price_lists.length > default_pagination_length ?
                        Math.ceil(price_lists.length / default_pagination_length) :
                        1
                    } shape="rounded" />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={[islandStyle]}>
            <FilterPriceLists />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}
