import axios from 'axios';
import { Dispatch } from 'redux';
import { RootState } from 'api/reducers';
import { Action } from 'api/action_types/price';
import { ActionType } from 'api/action_types/price';
import { AxiosError } from 'axios';
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { FinishedEdge } from 'api/types/price';
import { url_prefix } from 'settings';

const finished_edge_url: string = 'finished_edge';

export const getFinishedEdges = (price_list_id?: string | number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.GET_FINISHED_EDGES_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    let url = `${url_prefix}${finished_edge_url}/?`;

    if (price_list_id) {
      url += `price_list=${price_list_id}&`;
    }

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_FINISHED_EDGES_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.GET_FINISHED_EDGES_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const createFinishedEdge = (finished_edge: FinishedEdge) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.CREATE_FINISHED_EDGE_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${finished_edge_url}/`;

    try {
      const { data } = await axios.post(url, finished_edge, config);

      dispatch({
        type: ActionType.CREATE_FINISHED_EDGE_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.CREATE_FINISHED_EDGE_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const updateFinishedEdge = (finished_edge: FinishedEdge, id: number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.UPDATE_FINISHED_EDGE_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${finished_edge_url}/${id}/`;

    try {
      const { data } = await axios.patch(url, finished_edge, config);

      dispatch({
        type: ActionType.UPDATE_FINISHED_EDGE_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.UPDATE_FINISHED_EDGE_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const deleteFinishedEdge = (id: number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.DELETE_FINISHED_EDGE_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${finished_edge_url}/${id}/`;

    try {
      const { data } = await axios.delete(url, config);

      dispatch({
        type: ActionType.DELETE_FINISHED_EDGE_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.DELETE_FINISHED_EDGE_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }

    }
  }
}

export const updateLocalFinishedEdge = (finished_edge: FinishedEdge) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_LOCAL_FINISHED_EDGE,
      payload: finished_edge
    });
  }
}
