import { InsideDiagonalCorner } from "api/types/price";
import { InsideRadiusCorner } from "api/types/price";
import { RecessedDiagonalCorner } from "api/types/price";
import { ClippedCorner } from "api/types/price";
import { NotchedCorner } from "api/types/price";
import { BumpedOutCorner } from "api/types/price";
import { InstallationCharge } from "api/types/price";
import { FabricationCharge } from "api/types/price";
import { Miter } from "api/types/price";
import { Splash } from "api/types/price";
import { CooktopCutout } from "api/types/price";
import { FinishedEdge } from "api/types/price";
import { ApplianceEdge } from "api/types/price";
import { SinkCutout } from "api/types/price";
import { FaucetCutout } from "api/types/price";
import { OutletCutout } from "api/types/price";
import { RadiusCorner } from "api/types/price";
import { BumpIn } from "api/types/price";
import { BumpOut } from "api/types/price";
import { BumpInArc } from "api/types/price";
import { BumpOutArc } from "api/types/price";
import { FullArc } from "api/types/price";
import { PriceNavigation } from "api/types/price";
import { PriceError } from "api/types/price";
import { PriceHasError } from "api/types/price";
import { NONE } from "values/values";
import { UNDERMOUNT } from "values/values";

export const empty_clipped_corner: ClippedCorner = {
  taxable: true,
  price: 0
}

export const empty_bumped_out_corner: BumpedOutCorner = {
  taxable: true,
  price: 0
}

export const empty_notched_corner: NotchedCorner = {
  taxable: true,
  price: 0
}

export const empty_radius_corner: RadiusCorner = {
  taxable: true,
  price: 0
}

export const empty_inside_diagonal_corner: InsideDiagonalCorner = {
  taxable: true,
  price: 0
}

export const empty_inside_radius_corner: InsideRadiusCorner = {
  taxable: true,
  price: 0
}

export const empty_recessed_diagonal_corner: RecessedDiagonalCorner = {
  taxable: true,
  price: 0
}

export const empty_price_navigation: PriceNavigation = {
  show_price_list: false,
  show_material: false,
  show_splash: false,
  show_miter: false,
  show_waterfall: false,
  show_fabrication: false,
  show_installation: false,
  show_finished_edge: false,
  show_appliance_edge: false,
  show_sink_cutouts: false,
  show_cooktop_cutouts: false,
  show_outlet_cutouts: false,
  show_faucet_cutouts: false,
  show_notched_corners: false,
  show_clipped_corners: false,
  show_bumped_out_corners: false,
  show_radius_corners: false,
  show_inside_diagonal_corners: false,
  show_recessed_diagonal_corners: false,
  show_inside_radius_corners: false,
  show_bump_in: false,
  show_bump_out: false,
  show_bump_out_arc: false,
  show_bump_in_arc: false,
  show_full_arc: false
}

export const empty_installation_charge: InstallationCharge = {
  taxable: true,
  price: 0
}

export const empty_fabrication_charge: FabricationCharge = {
  taxable: true,
  price: 0
}

export const empty_faucet_cutout: FaucetCutout = {
  taxable: true,
  price: 0
}

export const empty_finished_edge: FinishedEdge = {
  taxable: true
}

export const empty_appliance_edge: ApplianceEdge = {
  taxable: true,
  price: 0
}

export const empty_miter: Miter = {
  taxable: true,
  price: 0
}

export const empty_splash: Splash = {
  taxable: true,
  price: 0
}

export const empty_waterfall: Splash = {
  taxable: true,
  price: 0
}

export const empty_sink_cutout: SinkCutout = {
  taxable: true,
  sink_type: UNDERMOUNT,
}

export const empty_cooktop_cutout: CooktopCutout = {
  taxable: true,
}

export const empty_outlet_cutout: OutletCutout = {
  taxable: true,
  price: 0
}

export const empty_full_arc: FullArc = {
  taxable: true,
  price: 0
}

export const empty_bump_in: BumpIn = {
  taxable: true,
  price: 0
}

export const empty_bump_out: BumpOut = {
  taxable: true,
  price: 0
}

export const empty_bump_out_arc: BumpOutArc = {
  taxable: true,
  price: 0
}

export const empty_bump_in_arc: BumpInArc = {
  taxable: true,
  price: 0
}

export const empty_error: PriceError = {
  price: "",
  material: "",
  material_type: "",
  price_list: "",
  name: "",
  height: ""
}

export const empty_has_error: PriceHasError = {
  price: false,
  material: false,
  material_type: false,
  name: false,
  height: false
}
