export const home_url: string = "/";
export const change_password_url: string = "change_password";
export const forgot_password_url: string = "forgot_password";
export const login_url: string = "login";
export const material_url: string = "material";
export const region_url: string = "region";
export const price_list_url: string = "price_list";
export const manage_suppliers_url: string = "manage_suppliers";
export const create_account_url: string = "create_account";
export const feedback_url: string = "feedback";
export const bug_url: string = "bug";
export const material_import_url: string = "material_import";
export const account_url: string = "account";

/// PRICE LIST ///
export const edge_url: string = "edge";
export const labor_url: string = "labor";
export const corner_url: string = "corner";
export const bump_url: string = "bump";
export const cutout_url: string = "cutout";
