import { Waterfall } from "api/types/price";

export enum ActionType {
  GET_WATERFALLS_PENDING = "GET_WATERFALLS_PENDING",
  GET_WATERFALLS_SUCCESS = "GET_WATERFALLS_SUCCESS",
  GET_WATERFALLS_FAIL = "GET_WATERFALLS_FAIL",
  CREATE_WATERFALL_PENDING = "CREATE_WATERFALL_PENDING",
  CREATE_WATERFALL_SUCCESS = "CREATE_WATERFALL_SUCCESS",
  CREATE_WATERFALL_FAIL = "CREATE_WATERFALL_FAIL",
  UPDATE_WATERFALL_PENDING = "UPDATE_WATERFALL_PENDING",
  UPDATE_WATERFALL_SUCCESS = "UPDATE_WATERFALL_SUCCESS",
  UPDATE_WATERFALL_FAIL = "UPDATE_WATERFALL_FAIL",
  UPDATE_LOCAL_WATERFALL = "UPDATE_LOCAL_WATERFALL",
}

interface getWaterfallsFail {
  type: ActionType.GET_WATERFALLS_FAIL,
  payload: string
}

interface getWaterfallsSuccess {
  type: ActionType.GET_WATERFALLS_SUCCESS,
  payload: Waterfall[]
}

interface getWaterfallsPending {
  type: ActionType.GET_WATERFALLS_PENDING
}

interface createWaterfallFail {
  type: ActionType.CREATE_WATERFALL_FAIL,
  payload: string
}

interface createWaterfallSuccess {
  type: ActionType.CREATE_WATERFALL_SUCCESS,
  payload: Waterfall[]
}

interface createWaterfallPending {
  type: ActionType.CREATE_WATERFALL_PENDING
}

interface updateWaterfallFail {
  type: ActionType.UPDATE_WATERFALL_FAIL,
  payload: string
}

interface updateWaterfallSuccess {
  type: ActionType.UPDATE_WATERFALL_SUCCESS,
  payload: Waterfall
}

interface updateWaterfallPending {
  type: ActionType.UPDATE_WATERFALL_PENDING
}

interface updateLocalWaterfall {
  type: ActionType.UPDATE_LOCAL_WATERFALL,
  payload: Waterfall
}

export type Action =
  getWaterfallsFail |
  getWaterfallsPending |
  getWaterfallsSuccess |
  createWaterfallPending |
  createWaterfallSuccess |
  createWaterfallFail |
  updateWaterfallPending |
  updateWaterfallSuccess |
  updateWaterfallFail |
  updateLocalWaterfall;
