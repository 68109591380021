/// REACT ///
import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useParams } from "react-router-dom";
/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// TYPES ///
import { ApplianceEdge } from "api/types/price";
import { FinishedEdge } from "api/types/price";
import { Miter } from "api/types/price";
import { Price } from "api/types/price";
import { Splash } from "api/types/price";
import { Waterfall } from "api/types/price";
/// ACTIONS ///
import { getApplianceEdges } from "api/actions/price/appliance_edge";
import { getFinishedEdges } from "api/actions/price/finished_edge";
import { getMiters } from "api/actions/price/miter";
import { getSplashes } from "api/actions/price/splash";
import { getWaterfalls } from "api/actions/price";
import { updateApplianceEdge } from "api/actions/price/appliance_edge";
import { updateFinishedEdge } from "api/actions/price/finished_edge";
import { updateMiter } from "api/actions/price/miter";
import { updateSplash } from "api/actions/price/splash";
import { updateWaterfall } from "api/actions/price";
import { createApplianceEdge } from "api/actions/price/appliance_edge";
import { createFinishedEdge } from "api/actions/price/finished_edge";
import { createMiter } from "api/actions/price/miter";
import { createSplash } from "api/actions/price/splash";
import { createWaterfall } from "api/actions/price";
/// MUI ///
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
/// ICONS ///
import AddIcon from "@mui/icons-material/Add";
/// HOC ///
import CreatePrice from "../dialog/create";
import EditablePrice from "./row";
import PriceListDrawer from "../drawer";
/// VALUE ///
import { getMaterialHeightDisplay } from "values/material";
/// STYLES ///
import { mainContentAreaStyle } from "styles/style";

export default function Edges() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { appliance_edges } = useAppSelector(state => state.price);
  const { finished_edges } = useAppSelector(state => state.price);
  const { miters } = useAppSelector(state => state.price);
  const { splashes } = useAppSelector(state => state.price);
  const { waterfalls } = useAppSelector(state => state.price);
  const [appliance_open, setApplianceOpen] = useState<boolean>(false);
  const [finished_open, setFinishedOpen] = useState<boolean>(false);
  const [miter_open, setMiterOpen] = useState<boolean>(false);
  const [splash_open, setSplashOpen] = useState<boolean>(false);
  const [waterfall_open, setWaterfallOpen] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      dispatch(getApplianceEdges(id));
      dispatch(getFinishedEdges(id));
      dispatch(getMiters(id));
      dispatch(getSplashes(id));
      dispatch(getWaterfalls(id));
    }
  }, [id, dispatch]);

  return (
    <Box sx={mainContentAreaStyle}>
      <Grid container alignItems="start" justifyContent="space-between">
        <Grid container item xs={8.5}>
          <Grid item xs={12}>
            <Typography variant="h1">
              Edges
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">
                Appliance Edges
              </Typography>
              <IconButton onClick={() => setApplianceOpen}>
                <AddIcon />
              </IconButton>
            </Stack>
          </Grid>
          {
            appliance_edges.map((ae: ApplianceEdge, index: number) => {
              return (
                <EditablePrice
                  index={index}
                  name={getMaterialHeightDisplay(ae?.material_thickness ?? "")}
                  price={ae?.price ?? 0}
                  taxable={ae?.taxable}
                  measurement="$/LIN"
                  setTaxable={(taxable: boolean) => dispatch(updateApplianceEdge({ taxable }, ae?.id))}
                  setPrice={(price: number) => dispatch(updateApplianceEdge({ price }, ae?.id))} />
              )
            })
          }
          <CreatePrice
            title="Create Appliance Edge Price"
            open={appliance_open}
            setOpen={setApplianceOpen}
            create={(price: Price) => dispatch(createApplianceEdge(price))}
          />
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">
                Finished Edges
              </Typography>
              <IconButton onClick={() => setApplianceOpen}>
                <AddIcon />
              </IconButton>
            </Stack>
          </Grid>
          {
            finished_edges.map((fe: FinishedEdge, index: number) => {
              return (
                <Fragment>
                  <EditablePrice
                    index={index}
                    name={`${getMaterialHeightDisplay(fe?.material_thickness ?? "")} Eased Edge`}
                    price={fe?.eased_price ?? 0}
                    taxable={fe?.taxable}
                    measurement="$/LIN"
                    setTaxable={(taxable: boolean) => dispatch(updateFinishedEdge({ taxable }, fe?.id))}
                    setPrice={(eased_price: number) => dispatch(updateFinishedEdge({ eased_price }, fe?.id))} />
                  <EditablePrice
                    index={index}
                    name={`${getMaterialHeightDisplay(fe?.material_thickness ?? "")} Bevel Edge`}
                    price={fe?.bevel_price ?? 0}
                    taxable={fe?.taxable}
                    measurement="$/LIN"
                    setTaxable={(taxable: boolean) => dispatch(updateFinishedEdge({ taxable }, fe?.id))}
                    setPrice={(bevel_price: number) => dispatch(updateFinishedEdge({ bevel_price }, fe?.id))} />
                  <EditablePrice
                    index={index}
                    name={`${getMaterialHeightDisplay(fe?.material_thickness ?? "")} Bullnose Edge`}
                    price={fe?.bullnose_price ?? 0}
                    taxable={fe?.taxable}
                    measurement="$/LIN"
                    setTaxable={(taxable: boolean) => dispatch(updateFinishedEdge({ taxable }, fe?.id))}
                    setPrice={(bullnose_price: number) => dispatch(updateFinishedEdge({ bullnose_price }, fe?.id))} />
                  <EditablePrice
                    index={index}
                    name={`${getMaterialHeightDisplay(fe?.material_thickness ?? "")} Specialty Edge`}
                    price={fe?.specialty_price ?? 0}
                    taxable={fe?.taxable}
                    measurement="$/LIN"
                    setTaxable={(taxable: boolean) => dispatch(updateFinishedEdge({ taxable }, fe?.id))}
                    setPrice={(specialty_price: number) => dispatch(updateFinishedEdge({ specialty_price }, fe?.id))} />
                </Fragment>
              )
            })
          }
          <CreatePrice
            title="Create Finished Edge Price"
            open={finished_open}
            setOpen={setFinishedOpen}
            create={(price: Price) => dispatch(createFinishedEdge(price))}
          />
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">
                Miters
              </Typography>
              <IconButton onClick={() => setApplianceOpen}>
                <AddIcon />
              </IconButton>
            </Stack>
          </Grid>
          {
            miters.map((fe: Miter, index: number) => {
              return (
                <EditablePrice
                  index={index}
                  name={getMaterialHeightDisplay(fe?.material_thickness ?? "")}
                  price={fe?.price ?? 0}
                  taxable={fe?.taxable}
                  measurement="$/LIN"
                  setTaxable={(taxable: boolean) => dispatch(updateMiter({ taxable }, fe?.id))}
                  setPrice={(price: number) => dispatch(updateMiter({ price }, fe?.id))} />
              )
            })
          }
          <CreatePrice
            title="Create Miter Price"
            open={miter_open}
            setOpen={setMiterOpen}
            create={(price: Price) => dispatch(createMiter(price))}
          />
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">
                Splashes
              </Typography>
              <IconButton onClick={() => setApplianceOpen}>
                <AddIcon />
              </IconButton>
            </Stack>
          </Grid>
          {
            splashes.map((fe: Splash, index: number) => {
              return (
                <EditablePrice
                  index={index}
                  name={getMaterialHeightDisplay(fe?.material_thickness ?? "")}
                  price={fe?.price ?? 0}
                  taxable={fe?.taxable}
                  measurement="$/LIN"
                  setTaxable={(taxable: boolean) => dispatch(updateSplash({ taxable }, fe?.id))}
                  setPrice={(price: number) => dispatch(updateSplash({ price }, fe?.id))} />
              )
            })
          }
          <CreatePrice
            title="Create Splash Price"
            open={splash_open}
            setOpen={setSplashOpen}
            create={(price: Price) => dispatch(createSplash(price))}
          />
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">
                Waterfall
              </Typography>
              <IconButton onClick={() => setApplianceOpen}>
                <AddIcon />
              </IconButton>
            </Stack>
          </Grid>
          {
            waterfalls.map((fe: Waterfall, index: number) => {
              return (
                <EditablePrice
                  index={index}
                  name={getMaterialHeightDisplay(fe?.material_thickness ?? "")}
                  price={fe?.price ?? 0}
                  taxable={fe?.taxable}
                  measurement="$/LIN"
                  setTaxable={(taxable: boolean) => dispatch(updateWaterfall({ taxable }, fe?.id))}
                  setPrice={(price: number) => dispatch(updateWaterfall({ price }, fe?.id))} />
              )
            })
          }
          <CreatePrice
            title="Create Waterfall Price"
            open={waterfall_open}
            setOpen={setWaterfallOpen}
            create={(price: Price) => dispatch(createWaterfall(price))}
          />
        </Grid>
        <Grid item xs={3}>
          <PriceListDrawer />
        </Grid>
      </Grid>
    </Box>
  )
}
