import { Splash } from "api/types/price";

export enum ActionType {
  GET_SPLASHES_PENDING = "GET_SPLASHES_PENDING",
  GET_SPLASHES_SUCCESS = "GET_SPLASHES_SUCCESS",
  GET_SPLASHES_FAIL = "GET_SPLASHES_FAIL",
  CREATE_SPLASH_PENDING = "CREATE_SPLASH_PENDING",
  CREATE_SPLASH_SUCCESS = "CREATE_SPLASH_SUCCESS",
  CREATE_SPLASH_FAIL = "CREATE_SPLASH_FAIL",
  UPDATE_SPLASH_PENDING = "UPDATE_SPLASH_PENDING",
  UPDATE_SPLASH_SUCCESS = "UPDATE_SPLASH_SUCCESS",
  UPDATE_SPLASH_FAIL = "UPDATE_SPLASH_FAIL",
  UPDATE_LOCAL_SPLASH = "UPDATE_LOCAL_SPLASH",
}

interface getSplashesFail {
  type: ActionType.GET_SPLASHES_FAIL,
  payload: string
}

interface getSplashesSuccess {
  type: ActionType.GET_SPLASHES_SUCCESS,
  payload: Splash[]
}

interface getSplashesPending {
  type: ActionType.GET_SPLASHES_PENDING
}

interface createSplashFail {
  type: ActionType.CREATE_SPLASH_FAIL,
  payload: string
}

interface createSplashSuccess {
  type: ActionType.CREATE_SPLASH_SUCCESS,
  payload: Splash[]
}

interface createSplashPending {
  type: ActionType.CREATE_SPLASH_PENDING
}

interface updateSplashFail {
  type: ActionType.UPDATE_SPLASH_FAIL,
  payload: string
}

interface updateSplashSuccess {
  type: ActionType.UPDATE_SPLASH_SUCCESS,
  payload: Splash
}

interface updateSplashPending {
  type: ActionType.UPDATE_SPLASH_PENDING
}

interface updateLocalSplash {
  type: ActionType.UPDATE_LOCAL_SPLASH,
  payload: Splash
}

export type Action =
  getSplashesFail |
  getSplashesPending |
  getSplashesSuccess |
  createSplashPending |
  createSplashSuccess |
  createSplashFail |
  updateSplashPending |
  updateSplashSuccess |
  updateSplashFail |
  updateLocalSplash;
