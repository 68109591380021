/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useParams } from "react-router-dom";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// ACTIONS ///
import { getMaterials } from "api/actions/material";
import { getSubRegion } from "api/actions/region";
import { clearMaterials } from "api/actions/material/material";
import { updateColor } from "api/actions/material";
import { updateLocalColor } from "api/actions/material/color";
/// TYPES ///
import { Color } from "api/types/material";
import { UpdateTime } from "api/types/update";
import { COLOR } from "api/types/update";
/// COMPONENTS ///
import Title from "components/universal/title";
import MaterialsDrawer from "./drawer/drawer";
import MaterialItem from "./row/material_item";
/// MUI ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
/// STYLES ///
import { islandStyle } from "styles/style";
import { mainContentAreaStyle } from "styles/style";

export default function Materials() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [update_pending, setUpdatePending] = useState<boolean>(false);
  const [start_times, setStartTimes] = useState<UpdateTime[]>([]);
  const [navigation, setNavigation] = useState<number>(-1);
  const { material } = useAppSelector((state) => state.material);
  const { sub_region } = useAppSelector((state) => state.region);

  useEffect(() => {
    dispatch(getMaterials(null, id));
    dispatch(getSubRegion(id));
    return () => {
      dispatch(clearMaterials());
    }
  }, [dispatch, id]);

  const start_update = (color: Color) => {
    setUpdatePending(true);
    let exists: boolean = false;
    for (let i = 0; i < start_times.length; i++) {
      if (color.id === start_times[i].data.id) {
        start_times[i] = { ...start_times[i], time: Math.round(new Date().getTime() / 1000), data: color };
        exists = true;
      }
    }
    if (!exists) {
      setStartTimes([...start_times, {
        time: Math.round(new Date().getTime() / 1000),
        type: COLOR,
        data: color
      }])
    }

    dispatch(updateLocalColor(color));
  }

  const update = (update_time: UpdateTime) => {
    const new_start_times = [...start_times.filter(item => item.data.id !== update_time.data.id)];
    setStartTimes(new_start_times);

    if (update_time.data.id) {
      dispatch(updateColor(update_time.data, update_time.data.id));
    }

    if (new_start_times.length === 0) {
      setUpdatePending(false);
    }
  }

  return (
    <Box sx={mainContentAreaStyle}>
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <Title
            title={`${sub_region?.name} Materials`}
            update_pending={update_pending}
            start_times={start_times}
            update={update}
            endProps={<div></div>} />
        </Grid>
        <Grid item xs={8.5}>
          <Paper sx={islandStyle}>
            <MaterialItem
              start_update={start_update}
              material={material} />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <MaterialsDrawer
            current_index={navigation}
            setNavigation={setNavigation} />
        </Grid>
      </Grid>
    </Box>
  )
}
